<template>
  <div>
    <div v-if="projects && projects.length > 0">
      <h2 class="directory--project-title mb-2 px-1" v-if="propertyTypeName">
        {{ $t(`directory.propertyType.${propertyTypeName}`) }}
      </h2>
      <div class="directory--project-card row">
        <div class="col-6" v-for="(project, index) in projects" :key="index">
          <card-project
            class="mb-4"
            :key="project.uuid"
            :project="project"
            :showCompare="false"
            :allowMultiImage="true"
          />
        </div>
      </div>
      <div class="directory--project-all mt-2">
        <router-link :to="seeAllPath(propertyTypeName)"
          >{{ $t('directory.search.title') }}
          {{ $t(`directory.propertyType.${propertyTypeName}`) }} ></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
const CardProject = () => import('@/components/utils/card-project');
import { mapState } from 'vuex';
export default {
  components: {
    CardProject,
  },
  props: {
    propertyTypeName: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({}),
  },
  data: () => ({
    projects: [],
  }),
  async mounted() {
    await this.getProjects();
  },
  watch: {
    propertyTypeName() {
      this.getProjects();
    },
  },
  methods: {
    async getProjects() {
      let propertyType = await this.$store.dispatch(
        'v2/masters/getPropertyTypeByName',
        this.propertyTypeName,
      );
      this.projects = await this.$store.dispatch('v2/directory/getDirectoryProject', {
        propertyType: propertyType,
      });
    },
    seeAllPath(propertyTypeName) {
      return encodeURI(
        '/cari/' + (propertyTypeName ? propertyTypeName.toLowerCase() : 'semua-tipe-properti'),
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
